<template>
  <v-container fluid>
    <v-form :readonly="!canEditSettings">
      <v-card-title>Trip Request Form Labels</v-card-title>
      <v-expansion-panels popout class="my-2">
        <v-expansion-panel>
          <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
            General
          </v-expansion-panel-header>
          <v-expansion-panel-content> </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
            Leave / Return
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense class="my-2">
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label to be used on request form for Overnight/Out-of-State option."
                  placeholder="Overnight or Out-of-State?"
                  v-model="labels.overnightOOS"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label to be used on request form for Out of County option."
                  placeholder="Out of County?"
                  v-model="labels.outOfCounty"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
            Destination
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense class="my-2">
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Message to be placed under 'Common Destinations' selection"
                  placeholder="If your destination is not found, scroll to bottom of the list, choose Other and enter the name and/or address of your destination in the space provided."
                  v-model="labels.commonDestinations"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
            Attendees
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense class="my-2">
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label for Number of Adults (non-Staff trips)"
                  placeholder="Number of Adults"
                  v-model="labels.numAdultsLabel"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label for Number of Adults (Staff Trips)"
                  placeholder="Number of Adults"
                  v-model="labels.numAdultsLabelStaffType"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label for Number of Students"
                  placeholder="Number of Students"
                  v-model="labels.numStudentsLabel"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label for Number of Special Needs Students"
                  placeholder="Number of Special Needs Students"
                  v-model="labels.numSpecialNeedsStudentsLabel"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label to be used for description of group or person(s) making trip."
                  placeholder="Description of group or person(s) making trip"
                  v-model="labels.groupDescription"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label to be used for asking about students' health concerns"
                  placeholder="Do you have students with health concerns on this trip? Don't list student names, only the health conditions due to FERPA/HIPPA."
                  v-model="labels.healthConcerns"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
            Transportation
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense class="my-2">
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label=" Label to be used for external transportation question."
                  placeholder="Will you be using external transportation (plane, public transportation or walking)?"
                  v-model="labels.extTransportation"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label to be used for external transportation mode."
                  placeholder="Please indicate mode of travel instead of, or in addition to, the reserved vehicle(s). Please include details of trip, including itineraries. "
                  v-model="labels.extTransportationMode"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label to be used for 'Do you need vehicles?'"
                  placeholder="Do you need Transportation to make arrangements for vehicles?"
                  v-model="labels.vehiclesNeeded"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label to be used for comments concerning vehicle needs"
                  placeholder="Comments or Details Concerning Needs"
                  v-model="labels.vehicleComments"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label for Need Special Needs Vehicles?"
                  placeholder="Need Special Needs Vehicles?"
                  v-model="labels.needSpecialNeedsVehicles"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label for # Special Needs Vehicles"
                  placeholder="# Special Needs Vehicles"
                  v-model="labels.numSpecialNeedsVehicles"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label for 'Do you need driver assigned?'"
                  placeholder="Driver Assigned label"
                  v-model="labels.driverAssigned"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label for Vehicle Driver Information"
                  placeholder="Vehicle Driver Information"
                  v-model="labels.vehDriverInfo"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label to be used for Vehicle Owner"
                  placeholder="Owner"
                  v-model="labels.vehicleOwner"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label to be used for driver hours this week in the Find Available Driver dialog"
                  placeholder="Rt Hrs This Wk"
                  v-model="labels.driverHours"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="4">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label for Number of Wheelchair Slots"
                  placeholder="Number of Wheelchair Slots"
                  v-model="labels.numWheelchairSlots"
                  hint="If blank, option will not appear in form"
                  persistent-hint
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="4">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label for Number of Safety Vests"
                  placeholder="Number of Safety Vests"
                  v-model="labels.numSafetyVests"
                  hint="If blank, option will not appear in form"
                  persistent-hint
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="4">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label for Number of Fold Down Seats"
                  placeholder="Number of Fold Down Seats"
                  v-model="labels.numFoldDownSeats"
                  hint="If blank, option will not appear in form"
                  persistent-hint
                ></v-textarea>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
            Funding
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense class="my-2">
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label to be used for Third Party Payment"
                  placeholder="Are funds payable to a third party?"
                  v-model="labels.thirdPartyPayment"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  outlined
                  rows="1"
                  auto-grow
                  label="Label to be used for add funding source"
                  placeholder="add funding source"
                  v-model="labels.addFundingSource"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
            Additional Information
          </v-expansion-panel-header>
          <v-expansion-panel-content> </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-text>
        <v-row dense> </v-row>
      </v-card-text>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';
import { cloneDeep } from 'lodash';

export default {
  inject: ['eventHub'],
  components: {},
  data: () => ({
    labels: {
      overnightOOS: '',
      outOfCounty: '',
      extTransportation: '',
      extTransportationMode: '',
      vehiclesNeeded: '',
      vehicleComments: '',
      groupDescription: '',
      healthConcerns: '',
      numStudentsLabel: '',
      numSpecialNeedsStudentsLabel: '',
      numAdultsLabel: '',
      numAdultsLabelStaffType: '',
      needSpecialNeedsVehicles: '',
      numSpecialNeedsVehicles: '',
      commonDestinations: '',
      vehicleOwner: '',
      thirdPartyPayment: '',
      addFundingSource: '',
      driverAssigned: '',
      vehDriverInfo: '',
      driverHours: '',
      numWheelchairSlots: '',
      numSafetyVests: '',
      numFoldDownSeats: '',
    },
  }),
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
  },
  created() {
    this.eventHub.$on('saveLabelsConfig', () => this.save());
  },
  async mounted() {
    await this.getTRConfig();
  },
  beforeDestroy() {
    this.eventHub.$off('saveLabelsConfig');
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async getTRConfig() {
      this.trConfig = await this.getConfig('tripRequestForm');
      if (this.trConfig && this.trConfig.labels) this.labels = this.trConfig.labels;
      this.baseFormValue = cloneDeep(this.labels);
    },
    async save() {
      try {
        const value = this.trConfig ? { ...this.trConfig, labels: this.labels } : { labels: this.labels };
        const r = await this.setConfig({ name: 'tripRequestForm', value });
        if (r && r.done) {
          this.baseFormValue = cloneDeep(this.labels);
          this.$myalert.success('Trip Request Form Labels saved');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
  },
  watch: {
    labels: {
      handler(value) {
        this.latestFormValue = cloneDeep(value);
      },
      deep: true,
    },
  },
  mixins: [promptBeforeLeave],
};
</script>

<style scoped>
.save-btn {
  float: right;
  margin-bottom: 12px;
}
</style>
