var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { attrs: { readonly: !_vm.canEditSettings } },
        [
          _c("v-card-title", [_vm._v("Trip Request Form Labels")]),
          _c(
            "v-expansion-panels",
            { staticClass: "my-2", attrs: { popout: "" } },
            [
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      staticClass:
                        "text-uppercase font-weight-medium grey lighten-3",
                    },
                    [_vm._v(" General ")]
                  ),
                  _c("v-expansion-panel-content"),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      staticClass:
                        "text-uppercase font-weight-medium grey lighten-3",
                    },
                    [_vm._v(" Leave / Return ")]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-2", attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label to be used on request form for Overnight/Out-of-State option.",
                                  placeholder: "Overnight or Out-of-State?",
                                },
                                model: {
                                  value: _vm.labels.overnightOOS,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.labels, "overnightOOS", $$v)
                                  },
                                  expression: "labels.overnightOOS",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label to be used on request form for Out of County option.",
                                  placeholder: "Out of County?",
                                },
                                model: {
                                  value: _vm.labels.outOfCounty,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.labels, "outOfCounty", $$v)
                                  },
                                  expression: "labels.outOfCounty",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      staticClass:
                        "text-uppercase font-weight-medium grey lighten-3",
                    },
                    [_vm._v(" Destination ")]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-2", attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Message to be placed under 'Common Destinations' selection",
                                  placeholder:
                                    "If your destination is not found, scroll to bottom of the list, choose Other and enter the name and/or address of your destination in the space provided.",
                                },
                                model: {
                                  value: _vm.labels.commonDestinations,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "commonDestinations",
                                      $$v
                                    )
                                  },
                                  expression: "labels.commonDestinations",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      staticClass:
                        "text-uppercase font-weight-medium grey lighten-3",
                    },
                    [_vm._v(" Attendees ")]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-2", attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label for Number of Adults (non-Staff trips)",
                                  placeholder: "Number of Adults",
                                },
                                model: {
                                  value: _vm.labels.numAdultsLabel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.labels, "numAdultsLabel", $$v)
                                  },
                                  expression: "labels.numAdultsLabel",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label for Number of Adults (Staff Trips)",
                                  placeholder: "Number of Adults",
                                },
                                model: {
                                  value: _vm.labels.numAdultsLabelStaffType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "numAdultsLabelStaffType",
                                      $$v
                                    )
                                  },
                                  expression: "labels.numAdultsLabelStaffType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label: "Label for Number of Students",
                                  placeholder: "Number of Students",
                                },
                                model: {
                                  value: _vm.labels.numStudentsLabel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "numStudentsLabel",
                                      $$v
                                    )
                                  },
                                  expression: "labels.numStudentsLabel",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label for Number of Special Needs Students",
                                  placeholder:
                                    "Number of Special Needs Students",
                                },
                                model: {
                                  value:
                                    _vm.labels.numSpecialNeedsStudentsLabel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "numSpecialNeedsStudentsLabel",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "labels.numSpecialNeedsStudentsLabel",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label to be used for description of group or person(s) making trip.",
                                  placeholder:
                                    "Description of group or person(s) making trip",
                                },
                                model: {
                                  value: _vm.labels.groupDescription,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "groupDescription",
                                      $$v
                                    )
                                  },
                                  expression: "labels.groupDescription",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label to be used for asking about students' health concerns",
                                  placeholder:
                                    "Do you have students with health concerns on this trip? Don't list student names, only the health conditions due to FERPA/HIPPA.",
                                },
                                model: {
                                  value: _vm.labels.healthConcerns,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.labels, "healthConcerns", $$v)
                                  },
                                  expression: "labels.healthConcerns",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      staticClass:
                        "text-uppercase font-weight-medium grey lighten-3",
                    },
                    [_vm._v(" Transportation ")]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-2", attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    " Label to be used for external transportation question.",
                                  placeholder:
                                    "Will you be using external transportation (plane, public transportation or walking)?",
                                },
                                model: {
                                  value: _vm.labels.extTransportation,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "extTransportation",
                                      $$v
                                    )
                                  },
                                  expression: "labels.extTransportation",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label to be used for external transportation mode.",
                                  placeholder:
                                    "Please indicate mode of travel instead of, or in addition to, the reserved vehicle(s). Please include details of trip, including itineraries. ",
                                },
                                model: {
                                  value: _vm.labels.extTransportationMode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "extTransportationMode",
                                      $$v
                                    )
                                  },
                                  expression: "labels.extTransportationMode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label to be used for 'Do you need vehicles?'",
                                  placeholder:
                                    "Do you need Transportation to make arrangements for vehicles?",
                                },
                                model: {
                                  value: _vm.labels.vehiclesNeeded,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.labels, "vehiclesNeeded", $$v)
                                  },
                                  expression: "labels.vehiclesNeeded",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label to be used for comments concerning vehicle needs",
                                  placeholder:
                                    "Comments or Details Concerning Needs",
                                },
                                model: {
                                  value: _vm.labels.vehicleComments,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.labels, "vehicleComments", $$v)
                                  },
                                  expression: "labels.vehicleComments",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label for Need Special Needs Vehicles?",
                                  placeholder: "Need Special Needs Vehicles?",
                                },
                                model: {
                                  value: _vm.labels.needSpecialNeedsVehicles,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "needSpecialNeedsVehicles",
                                      $$v
                                    )
                                  },
                                  expression: "labels.needSpecialNeedsVehicles",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label: "Label for # Special Needs Vehicles",
                                  placeholder: "# Special Needs Vehicles",
                                },
                                model: {
                                  value: _vm.labels.numSpecialNeedsVehicles,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "numSpecialNeedsVehicles",
                                      $$v
                                    )
                                  },
                                  expression: "labels.numSpecialNeedsVehicles",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label for 'Do you need driver assigned?'",
                                  placeholder: "Driver Assigned label",
                                },
                                model: {
                                  value: _vm.labels.driverAssigned,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.labels, "driverAssigned", $$v)
                                  },
                                  expression: "labels.driverAssigned",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label: "Label for Vehicle Driver Information",
                                  placeholder: "Vehicle Driver Information",
                                },
                                model: {
                                  value: _vm.labels.vehDriverInfo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.labels, "vehDriverInfo", $$v)
                                  },
                                  expression: "labels.vehDriverInfo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label: "Label to be used for Vehicle Owner",
                                  placeholder: "Owner",
                                },
                                model: {
                                  value: _vm.labels.vehicleOwner,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.labels, "vehicleOwner", $$v)
                                  },
                                  expression: "labels.vehicleOwner",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label to be used for driver hours this week in the Find Available Driver dialog",
                                  placeholder: "Rt Hrs This Wk",
                                },
                                model: {
                                  value: _vm.labels.driverHours,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.labels, "driverHours", $$v)
                                  },
                                  expression: "labels.driverHours",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label: "Label for Number of Wheelchair Slots",
                                  placeholder: "Number of Wheelchair Slots",
                                  hint: "If blank, option will not appear in form",
                                  "persistent-hint": "",
                                },
                                model: {
                                  value: _vm.labels.numWheelchairSlots,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "numWheelchairSlots",
                                      $$v
                                    )
                                  },
                                  expression: "labels.numWheelchairSlots",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label: "Label for Number of Safety Vests",
                                  placeholder: "Number of Safety Vests",
                                  hint: "If blank, option will not appear in form",
                                  "persistent-hint": "",
                                },
                                model: {
                                  value: _vm.labels.numSafetyVests,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.labels, "numSafetyVests", $$v)
                                  },
                                  expression: "labels.numSafetyVests",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label: "Label for Number of Fold Down Seats",
                                  placeholder: "Number of Fold Down Seats",
                                  hint: "If blank, option will not appear in form",
                                  "persistent-hint": "",
                                },
                                model: {
                                  value: _vm.labels.numFoldDownSeats,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "numFoldDownSeats",
                                      $$v
                                    )
                                  },
                                  expression: "labels.numFoldDownSeats",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      staticClass:
                        "text-uppercase font-weight-medium grey lighten-3",
                    },
                    [_vm._v(" Funding ")]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-2", attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label to be used for Third Party Payment",
                                  placeholder:
                                    "Are funds payable to a third party?",
                                },
                                model: {
                                  value: _vm.labels.thirdPartyPayment,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "thirdPartyPayment",
                                      $$v
                                    )
                                  },
                                  expression: "labels.thirdPartyPayment",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  rows: "1",
                                  "auto-grow": "",
                                  label:
                                    "Label to be used for add funding source",
                                  placeholder: "add funding source",
                                },
                                model: {
                                  value: _vm.labels.addFundingSource,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.labels,
                                      "addFundingSource",
                                      $$v
                                    )
                                  },
                                  expression: "labels.addFundingSource",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      staticClass:
                        "text-uppercase font-weight-medium grey lighten-3",
                    },
                    [_vm._v(" Additional Information ")]
                  ),
                  _c("v-expansion-panel-content"),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-card-text", [_c("v-row", { attrs: { dense: "" } })], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }